.window {
    height: 100%;
}

.container {
    width: 80%;
    animation: container 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    border: 1px solid #1b6d85;
    border-radius: 10px;
    color: white;
    padding: 10px;
    margin: 15px 0;
    background: #1b6d8580;
}

.notification_container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: none;
    border-radius: 10px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.notification_container > p {
    margin-top: 10px;
    margin-bottom: 10px;
}

.button {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}

.button > button {
    height: 3em;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    width: 40%;
    margin: 20px;
}

.button > button:hover {
    background: #1b6d8580;
}

.button > button:active {
    background: darkcyan;
}

@media screen and (min-width: 375px) and (max-width: 809px) {

    .container {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-top: 10px;
    }

}

@keyframes container {
    0% {
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}

.filter_sorted {
    display: flex;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}

.filter {
    height: 3em;
    width: 40%;
    background: #1b6d8580;
    border: none;
    border-radius: 10px;
    color: white;
    padding-left: 15px;
    margin-top: 10px;
    outline: none;
}

.editor{
    font-family: "Inter", sans-serif;
    font-style: normal;
}

.card_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: card_form 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.title_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.title_input {
    width: 30em;
    height: 3em;
    background: #155466;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px;
}


.text_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.textarea_input {
    width: 40em;
    height: 10em;
    background: #155466;
    border: none;
    border-radius: 10px;
    color: white;
    resize: none;
    padding: 5px;
}


.edit_button {
    width: 20em;
    height: 3em;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    opacity: 0.8;
}

.edit_button:hover {
    opacity: 1;
    font-weight: bold;
}

.edit_button:active {
    transform: scale(1.1);
    opacity: 0.5;
}