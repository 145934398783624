.avatar_modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.empty_area {
    margin-top: 15px;
}

.empty_area > div > div > label {
    color: white !important;
}

.button_avatar {
    width: 150px;
    height: 40px;
    background: transparent;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px;
}

.button_avatar:hover {
    background: #1b6d8580;
}

.button_avatar:active {
    background: #1b6d8520;
}

.main {
    animation: container 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.avatar {
    width: 150px;
}

.avatar > img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 0 20px;
}

.notification_settings {
    width: 80%;
}

.container {
    display: table-row-group;
    flex-direction: column;
    width: 60%;
    margin: 3% 0 3%;
}

.inner_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1% 0 3%;
}

.inner_container > div {
    display: flex;
}

.inner_container > div > input {
    margin-right: 10px;
}

.button {
    width: 100%;
    height: 40px;
    background: transparent;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    margin-top: 15px;
    font-size: 20px;
}

.input_area {
    height: 2em;
    background: #1b6d8580;
    border-radius: 10px;
    padding-left: 15px;
    border: 1px solid #1b6d85;
    margin-left: 15px;
    width: 350px;
    color: white;
}

.name_container > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3% 0 3%;
    align-items: center;
}

.file_upload input[type="file"] {
    display: none; /* скрываем input file */
}

/* задаем стили кнопки выбора файла*/
.file_upload {
    position: relative;
    overflow: hidden;
    width: 365px;
    height: 40px;
    background: transparent;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    border: 1px solid;
    font-size: 20px;
}

.file_upload:hover {
    background: #1b6d8580;
}

.button:hover {
    background: #1b6d8580;
}

/* Растягиваем label на всю область блока .file-upload */
.file_upload > label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* стиль текста на кнопке*/
.file_upload span {
    line-height: 40px;
}

.helper {
    padding: 10px 10px 15px;
    color: white;
}

.helper h2 {
    color: transparent;
}

.helper a {
    color: #0ce1cf;
    font-size: 15px;
    text-decoration: none;
}

.logo_telegram {
    width: 30px;
    height: 20px;
    background: url("../../static/logo_telegram.png") no-repeat;
    /*color: transparent;*/
    display: inline;
    /*display: block;*/
}

.logo_telegram:hover {
    transform: scale(1.3);
}

.logo_vk {
    width: 30px;
    height: 20px;
    background: url("../../static/logo_vk.png") no-repeat;
    display: block;
    /*color: transparent;*/
}

.logo_vk:hover {
    transform: scale(1.3);
}

@media screen and (min-width: 375px) and (max-width: 809px) {

    .input_area {
        width: 200px;
        color: white;
    }

    .container {
        width: 100%;
        display: flex;
    }

    .notification_settings {
        width: 100%;
    }

    .avatar {
        margin: 5% 0 5%;
    }

}

@keyframes container {
    0% {
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}