.invisibleScroll {
    overflow-y: scroll;
    scrollbar-width: none; /* For Firefox */
    msOverflowStyle: none; /* For IE and Edge */

    /* For Webkit browsers like Chrome and Safari */
    &::-webkit-scrollbar {
        display: none;
    }
}
