.sorted__icon {
    position: relative;
    display: flex;

}

option {
    border: none;
    background: #1b6d85;
    color: #ffffff;
}

.sorted {
    height: 3em;
    background: #1b6d8580;
    border: none;
    border-radius: 10px;
    color: white;
    padding-left: 20px;
    margin-top: 10px;
    outline: none;
    width: 200px;
}

.sorted_up {
    display: flex;
    padding-left: 20px;
    padding-top: 20px;
}
