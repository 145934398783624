.footer {
    position: sticky;
    bottom: 0;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footerItem{
    display: flex;
}

.logo_vk {
    width: 30px;
    height: 20px;
    background: url("../../static/logo_vk.png") no-repeat;
    display: block;
}

.logo_vk:hover {
    transform: scale(1.3);
}

.bugReport:hover {
    color: red;
}