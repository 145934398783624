.loader {
    width: 150px;
    height: 150px;
    border: transparent solid 5px;
    border-left-color: #4fbfff;
    border-top-color: #4fbfff;
    border-radius: 100%;
    position: relative;
    animation: loading 5s infinite linear;
}

.loader::before {
    position: absolute;
    content: '';
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: transparent solid 5px;
    border-left-color: #4fbfff;
    border-top-color: #4fbfff;
    border-radius: 100%;
    animation: loading 2s infinite reverse;
}

.loader::after {
    position: absolute;
    content: '';
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    border: transparent solid 5px;
    border-left-color: #4fbfff;
    border-top-color: #4fbfff;
    border-radius: 100%;
    animation: loading 4s infinite linear;
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
