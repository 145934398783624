.theme_notification {
    height: 20em;
    background: #1b6d8580;
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    color: white;
    padding-top: 15px;
    padding-left: 15px;
    resize: none;
}

.card_form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: card_form 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.button {
    height: 3em;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
}

.button:hover {
    background: #1b6d8580;
}

.card_form > label {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card_form > label > input {
    height: 3em;
    background: #1b6d8580;
    border: none;
    border-radius: 10px;
    color: white;
    padding-left: 15px;
    margin-top: 10px;
}

.area_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

@media screen and (min-width: 375px) and (max-width: 809px) {

    .card_form {
        width: 100%;
    }

}

@keyframes card_form {
    0% {
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}