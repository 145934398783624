.list {
    list-style: none;
}

.start_page {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    animation: start_page 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

@media screen and (min-width: 375px) and (max-width: 809px) {

    .start_page {
        width: 100%;
    }

}

@keyframes start_page {
    0% {
        transform: translateZ(-1400px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0);
        opacity: 1;
    }
}
