.container * {
    font-family: "Inter", sans-serif;
    font-style: normal;
}

.card_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: card_form 3s cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.name_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button {
    width: 20em;
    height: 3em;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    color: white;
    opacity: 0.8;
}

.button:hover {
    opacity: 1;
    font-weight: bold;
}

.button:active {
    transform: scale(1.1);
    opacity: 0.5;
}

.name_input {
    width: 20em;
    height: 3em;
    background: #155466;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 5px;
}

.textarea_input {
    width: 20em;
    height: 5em;
    background: #155466;
    border: none;
    border-radius: 10px;
    color: white;
    resize: none;
    padding: 5px;
}
