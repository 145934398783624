* {
    margin: 0;
    padding: 0;
    cursor: default;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    src: local(''),
    url('static/fonts/inter-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */ url('static/fonts/inter-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

.header {
    position: sticky;
    top: 0;
    z-index: 9;
}

.container {
    width: 80%;
    align-self: center;
}

.body {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-image: url("static/background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: white;
    font-family: Inter, sans-serif;
}

.main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 90px;
}

.router {
    width: 80%;
    height: 70%;
}

.menu {
    text-decoration: none;
    position: sticky;
    top: 30%;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
}

@media screen and (min-width: 375px) and (max-width: 809px) {

    .body {
        min-height: 100vh;
    }

    .container {
        width: 90%;
    }

    .main {
        flex-direction: column;
    }

    .router{
        width: 100%;
        height: 70%;
        align-self: center;
    }

    .menu {
        width: 100%;
    }

}